/** @format */

.App {
    /* text-align: center; */
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.dropdown-item-checked::before {
    position: absolute;
    right: 0.4rem;
    content: '✓';
    font-weight: 600;
}
.hideBorder {
    /* border-top: 0 none;
    justify-content: center;
    align-items: center; */
    border: 'none';
    border-top: none;
    border-bottom: none;
}
.setFooterBtn {
    display: flex;
}
.cardBorder {
    bordercolor: 'white';
    bordertopleftradius: 1;
    borderstyle: 'solid';
    /* height: 200px; */
    /* width: 700px; */
}
.fa {
    font-size: 49px;
    color: 'text-warning';
}
.label {
    /* Other styling... */
    /* text-align: right; */
    /* clear: both; */
    /* float: left; */
    /* margin-right: 15px; */
    padding-left: 0px;
}

.custom-date-picker-icon {
    right: 5px;
    top: 5px;
    float: right;
    position: absolute;
    z-index: 2;
    color: black;
}
.loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.positionLoader {
    position: relative;
    /* left: 30px; */
    /* top: 70px; */
    /* width: 500px; */
    background-color: #000;
    /* border: solid 3px #ff7347; */
    /* font-size: 24px; */
    /* text-align: center; */
}
.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 7%;
    width: 100%;
    background-color: gainsboro;
    color: white;
    text-align: center;
}

.columnContainer {
    /* display: flex; */
    height: 100%;
    /* flex-direction: row; */
    justify-content: start;
}
.leftContainer {
    flex: 1;
    height: 200px;
    background-color: gray;
}

.rightContainer {
    flex: 1;
    height: 200px;
    /* background-color: blue; */
    /* justify-content: right; */
    /* align-items: right; */
}

.middleContainer {
    flex: 1;
    height: 200px;
    /* background-color: green; */
}
.leftAlignStack {
    flex: 1;
    background-color: white;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 40px;
}
.renderImg {
    height: 100px;
    width: 50px;
}

.inputNotesSize {
    height: 300px;
}
.displayRow {
    display: flex; /* establish flex container */
    flex-direction: row; /* default value; can be omitted */
    flex-wrap: nowrap; /* default value; can be omitted */
    justify-content: space-between; /* switched from default (flex-start, see below) */
    /* background-color: lightyellow; */
}
.footerModalButton {
    display: flex;
    justify-content: space-between;
}
.cardFooterContent{
    flex: 1,
    
  }
  .rectangle {
    height: 200px;
    width: 200px;
    border-radius: 1;
    border-color: black;
    /* background-color:blue; */
  }
