@import "../variables";



.form-group {
    margin-bottom: 1.5rem;
    label {
        font-size: 0.9375rem;
        line-height: 1.25rem;
        margin-bottom: 0.125rem;
    }
    
}
.form-control {
    height: 40px;
    border-radius: 2px;
    background-color: #FFFFFF;
    min-width: 10rem;
    border: 1px solid rgba(57,69,93,0.25);
    font-size: 0.9375rem;
    color: $gray;
    line-height: 1.25rem;
    &:focus {
        color: inherit;
        border-color: #958bef;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(1,79,236,.25);
        ~ .c-icon {
            color: $primary;
        }
    }
    &::placeholder {
        color: rgba(57, 69, 93, 0.7);
    }
    .form-text.help-block {
        font-size: 0.75rem;
        margin-top:0.125rem;
        line-height: 1rem;
        white-space: pre-line;
    }
}

.search-field {
    width: 300px;
    display: flex;
    align-items: center;
    position: relative;
    .fa-search, .c-icon {
        position: absolute;
        left: 0.75rem;
        top: 0.75rem;
    }
    .form-control {
        padding-left:2.25rem;
    }
}
.input-group {
    height: calc(2em + 0.5rem + 2px);
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid;
    color: rgba(57, 69, 93, 0.7);
    background-color: #FFFFFF;
    border-color: rgba(57, 69, 93, 0.2);
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-radius:2px;
    display:flex;
    flex-direction:row;
    align-items: center;
    .input-group-prepend + input.form-control {
        margin-left: 0.5rem;
    }
    input.form-control {
        flex:1;
        border:none;
        padding:0;
        height: 20px;
    }
}


.settings {
    .col-md-4 {
        width: 324px;
    }
}