.table {
    thead {
        th {
            padding: 0 0.5rem 0.5rem;
            color: $muted;
            font-weight: 400;
            font-size: 0.9375rem;
            line-height: 1.25rem;
            border-bottom: 1px solid rgba(57,69,93,0.25);
        }
    }
    tbody {
        tr{
            background-color: rgba(255,255,255,0.1);
            box-shadow: inset 0 -1px 0 0 rgba(57,69,93,0.1);
            transition: background-color 0.25s;
            td {
                line-height: 1.25rem;
                padding: 0.75rem 0.5rem;
                font-size: 0.9375rem;
                border: none;
            }
            &:hover {
                background-color: rgba(57,69,93,0.05);
            }
        }
    }
}