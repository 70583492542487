/** @format */

// Here you can add other styles
.btn-multiple-state {
    .spinner {
        width: 36px;
        text-align: center;
    }

    .spinner > span {
        width: 6px;
        height: 6px;
        background-color: white;
        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-bouncedelay 1.2s infinite ease-in-out
            both;
        animation: sk-bouncedelay 1.2s infinite ease-in-out both;
    }

    .spinner .bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    .spinner .bounce2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }
    .label {
        line-height: 20px;
        font-weight: 500;

    }
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
.bg-primary {
    background-color: $primary !important;
}

.btn-multiple-state {
    position: relative;
    transition: opacity 500ms;

    .spinner,
    .icon {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        opacity: 0;
        visibility: hidden;
        top: 50%;
        transform: translateY(-50%);
        transition: opacity 500ms;
        color: white;
    }

    .icon i {
        vertical-align: text-bottom;
        font-size: 18px;
    }

    .label {
        transition: opacity 500ms;
    }

    &.show-spinner {
        .label {
            opacity: 0;
        }

        .spinner {
            opacity: 1;
            visibility: visible;
        }
    }

    &.show-success {
        .label {
            opacity: 0;
        }

        .icon.success {
            opacity: 1;
            visibility: visible;
        }
    }

    &.show-fail {
        .label {
            opacity: 0;
        }

        .icon.fail {
            opacity: 1;
            visibility: visible;
        }
    }

    &.btn-primary:disabled {
        opacity: .6;
        background: darken($primary, 10%);
        border-color: darken($primary, 10%);
        cursor: not-allowed;
    }

    &.btn-secondary:disabled {
        opacity: 1;
        border-color: darken($secondary, 10%);
    }
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-color: transparent;
    color: $primary;
}
.nav-tabs .nav-link {
    color: #768192;
    &.active {
        color: $primary;
        background-color: inherit;
        border-color: transparent;
        box-shadow: none;
        outline: none;
        border-bottom: 4px solid $primary;
    }
}

.c-app {
    background-color: white;
}
.c-sidebar {
    box-shadow: 8px 0 14px 0 rgba(1,51,152,0.15);
    background: linear-gradient(0deg, #226BFF 0%, #014DE6 100%);
    background-color: $primary;
    z-index: 0;
    .c-sidebar-brand {
        background-color: transparent;
    }
}
.btn-primary {
    background-color: $primary;
    border-color: $primary;
}
.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    background: darken($primary, 10%);
}


// .c-app {
//     // height: 100vh;
// }
// .c-app.has-footer {
//     height: calc(100vh - 3rem);
//     min-height: calc(100vh - 3rem);
// }

.table {
    th,
    td {
        border-top: 0;
        border-bottom: 1px solid #d8dbe0;
    }
}

$start: 0;
$end: 20;
$step: 5;

@for $i from $start through $end {
    $current: ($i * $step);
    .t-#{$current} {
        top: #{$current + '%'} !important;
    }
    .b-#{$current} {
        bottom: #{$current + '%'} !important;
    }
    .l-#{$current} {
        left: #{$current + '%'} !important;
    }
    .r-#{$current} {
        right: #{$current + '%'} !important;
    }
    .mvh-#{$current} {
        min-height: #{$current + 'vh'} !important;
    }
    .mvw-#{$current} {
        min-width: #{$current + 'vw'} !important;
    }
}

.react-calendar.react-calendar--doubleView {
    position: absolute;
    right: 0;
    z-index: 1000;
    max-width: unset;
}
.tippy-box {
    border: 1px solid #ccc;
    box-shadow: 0 6px 23px 0 rgba(57, 69, 93, 0.75);
    .tippy-content {
        max-width: none;
    }
}

.action-bar {
    position: sticky;
    top: 0;
    background-color: white;
    // padding: 8px;
    z-index: 1;
    min-width: 100%;
}
.flexed {
    flex: 1;
}
.vh-100 {
    min-height: 100vh !important;
}

.image-card {
    width: 30vw;
    height: 30vw;
    object-fit: cover;
}

#lightbox-content {
    position: absolute;
    left: 62px;
    top: 175px;
    color: white;
    z-index: 1;
}

#lightbox-img {
    flex: 0.7;
    // height: 80vh;
    max-width: 80vw;
    width: 80px;
    object-fit: contain;
    margin-right: 20px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
}


.lightbox-desciption-button {
    position: absolute;
    left: 50%;
    z-index: 1;
    top: 5%;
}

.lightbox-description {
    color: white;
}

.customButton {
    position: relative;
    left: -50%;
}

.left-button {
    position: absolute;
    left: 4%;
}

.right-button {
    position: absolute;
    right: 4%;
}

.content {
    color: white;
    font-size: 22px;
    flex: 0.3;
    text-align: justify;
    overflow-y: scroll;
    height: 80vh;
    margin: 0 20px;
}

// TODO: design changes D-24-03-2022
#min_amount {
    width: 250px;
}



//TODO: ADD IF WE WANT TO CHANGE DESIGN OF HOW PDF IS SHOWEN
.react-pdf__Page__canvas {
    overflow-y: scroll;
}

@media only screen and (max-width: 600px) {

    .content {
        flex: 1 !important;
        width: 100%;
    }

    .close-button {
        position: absolute;
        z-index: 1;
        top: 40px;
        right: 25px;
        color: #fff !important;
        cursor: pointer;
    }

    .react-pdf__Page__canvas {
        overflow-y: scroll;
    }

    .download-button {
        position: absolute;
        z-index: 1;
        top: 90px;
        right: 190px;
        // color: #000 !important;
        // cursor: pointer;
        // background-color: #fff;
        // border-radius: 5px;
        // padding: 10px;
        // text-decoration: none;
    }
}

/*Completely optional styling that has nothing to do with the lightbox*/
button {
    color: white;
    border: 2px solid rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 1.2rem;
}

img:hover,
button:hover {
    cursor: pointer;
}

::-webkit-scrollbar {
    // width: 1rem;
    // height: 1rem;
    background: #fff;
    width: 8px;
    height: 8px;
    //box-shadow: inset -1px 0 0 0 rgba(162,162,162,0.5);
    margin:2px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,0.26)
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0,0,0,0.4)
}

.check-list {
    list-style: none;
}

$separator-color: '#ccc';
$input-background: transparent;
$primary-color: #0087f7;
$foreground-color: #fff;
$theme-color-1: $primary-color;
$button-text-color: white;
$error-color: red;

.dropzone {
    min-height: 150px !important;
    border: 1px solid $separator-color !important;
    background: $input-background !important;
    padding: 10px 10px !important;
    border-radius: $border-radius !important;
    color: $primary-color !important;
    height: auto !important;

    .img-thumbnail {
        //   height: 58px !important;
        //   width: 100% !important;
        object-fit: contain !important;
    }
    &.is-invalid .dz-message {
        border-color: #e55353 !important;
    }
}

.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
    position: relative;
    // transform: translateY(-50%);
    // top: 24px !important;
    margin: 0;
}

.dropzone .dz-preview.dz-image-preview,
.dropzone .dz-preview.dz-file-preview {
    width: 260px;
    height: 60px;
    min-height: unset;
    border: 1px solid $separator-color !important;
    border-radius: $border-radius !important;
    background: $foreground-color !important;
    color: $primary-color !important;

    .preview-container {
        transition: initial !important;
        animation: initial !important;
        margin-left: 0;
        margin-top: 0;
        position: relative;
        width: 100%;
        height: 100%;

        i {
            color: $theme-color-1;
            font-size: 20px;
            position: absolute;
            left: 50%;
            top: 29px;
            transform: translateX(-50%) translateY(-50%) !important;
            height: 22px;
        }
    }

    strong {
        font-weight: normal;
    }

    .remove {
        position: absolute;
        right: 5px;
        top: 5px;
        color: $theme-color-1 !important;
    }

    .dz-details {
        position: static;
        display: block;
        opacity: 1;
        text-align: left;
        min-width: unset;
        z-index: initial;
        color: $primary-color !important;
    }

    .dz-error-mark {
        color: $button-text-color !important;
        top: 15px;
        left: 25px;
        margin-left: 0;
        margin-top: 0;

        span {
            display: inline-block;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 28'%3E%3Cpath style='fill:" + encodecolor(
                    $theme-color-1
                ) + ";' d='M4.1,23.9A13.51,13.51,0,0,1,0,14,13.52,13.52,0,0,1,4.1,4.1,13.52,13.52,0,0,1,14,0a13.52,13.52,0,0,1,9.9,4.1A13.52,13.52,0,0,1,28,14a13.51,13.51,0,0,1-4.1,9.9A13.52,13.52,0,0,1,14,28,13.52,13.52,0,0,1,4.1,23.9Z'/%3E%3Cpath style='fill:" + encodecolor(
                    $foreground-color
                ) + ";' d='M13.13,19.35V6.17a.88.88,0,1,1,1.75,0V19.35Z'/%3E%3Crect style='fill:" + encodecolor(
                    $foreground-color
                ) + ";' x='13.13' y='21.07' width='1.75' height='1.64'/%3E%3C/svg%3E");
            width: 28px;
            height: 28px;
        }
    }

    .dz-success-mark {
        color: $button-text-color;
        top: 15px;
        left: 25px;
        margin-left: 0;
        margin-top: 0;

        span {
            display: inline-block;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 28'%3E%3Cpath style='fill:" + encodecolor(
                    $theme-color-1
                ) + ";' d='M4.1,23.9A13.51,13.51,0,0,1,0,14,13.52,13.52,0,0,1,4.1,4.1,13.52,13.52,0,0,1,14,0a13.52,13.52,0,0,1,9.9,4.1A13.52,13.52,0,0,1,28,14a13.51,13.51,0,0,1-4.1,9.9A13.52,13.52,0,0,1,14,28,13.52,13.52,0,0,1,4.1,23.9Z'/%3E%3Cpath style='fill:" + encodecolor(
                    $foreground-color
                ) + ";' d='M20.14,8.81A.77.77,0,0,1,21.2,9a.81.81,0,0,1,.25.61.83.83,0,0,1-.25.62L12.48,19l-.11.1a.82.82,0,0,1-1.23,0L6.79,14.74l-.11-.16a.49.49,0,0,1-.08-.18,1.06,1.06,0,0,1,0-.19.61.61,0,0,1,0-.19,1.16,1.16,0,0,1,0-.18,1.26,1.26,0,0,1,.08-.18,1,1,0,0,1,.11-.15.87.87,0,0,1,1.26,0l3.69,3.7L19.94,9A.72.72,0,0,1,20.14,8.81Z'/%3E%3C/svg%3E");
            width: 28px;
            height: 28px;
        }
    }

    .dz-progress {
        width: 84%;
        margin-left: 0;
        margin-top: 0;
        right: 0;
        height: 5px !important;
        left: 15px;

        .dz-upload {
            width: 100%;
            background: $theme-color-1 !important;
        }
    }

    .dz-error-message {
        border-radius: 15px;
        background: $error-color !important;
        top: 60px;

        &:after {
            border-bottom: 6px solid $error-color !important;
        }
    }

    [data-dz-name] {
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 90%;
        display: inline-block;
        overflow: hidden;
    }
}

.dropzone .dz-preview.dz-file-preview .img-thumbnail {
    display: none;
}

.dropzone .dz-error.dz-preview.dz-file-preview {
    .preview-icon {
        display: none;
    }

    .dz-error-mark,
    .dz-success-mark {
        color: $theme-color-1 !important;
    }
}

.dropzone .dz-preview.dz-image-preview .preview-icon {
    display: none;
}

@-webkit-keyframes pulse-inner {
    0% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    10% {
        -webkit-transform: scale(0.8, 1);
        -moz-transform: scale(0.8, 1);
        -ms-transform: scale(0.8, 1);
        -o-transform: scale(0.8, 1);
        transform: scale(0.8, 1);
    }

    20% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@-moz-keyframes pulse-inner {
    0% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    10% {
        -webkit-transform: scale(0.8, 1);
        -moz-transform: scale(0.8, 1);
        -ms-transform: scale(0.8, 1);
        -o-transform: scale(0.8, 1);
        transform: scale(0.8, 1);
    }

    20% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@keyframes pulse-inner {
    0% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    10% {
        -webkit-transform: scale(0.8, 1);
        -moz-transform: scale(0.8, 1);
        -ms-transform: scale(0.8, 1);
        -o-transform: scale(0.8, 1);
        transform: scale(0.8, 1);
    }

    20% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
    -webkit-animation: pulse-inner 3s ease infinite;
    -moz-animation: pulse-inner 3s ease infinite;
    -ms-animation: pulse-inner 3s ease infinite;
    -o-animation: pulse-inner 3s ease infinite;
    animation: pulse-inner 3s ease infinite;
}

.rtl {
    .dropzone .dz-preview.dz-image-preview .dz-progress {
        right: 50%;
        left: initial;
        transform: translateX(50%);
    }

    .dropzone .dz-preview.dz-file-preview .remove,
    .dropzone .dz-preview.dz-image-preview .remove {
        right: initial;
        left: 5px;
    }
}

.filepicker.dropzone {
    border: none !important;
    .dz-message {
        flex: 1;
        margin: 0;
        padding: 3rem;
        border: 1px dashed #ccc;
    }
    &.dz-started .dz-message {
    }
    .remove {
    }
}
.dropzone .dz-preview.dz-image-preview .remove,
.dropzone .dz-preview.dz-file-preview .remove {
    cursor: pointer;
    bottom: 15px;
    right: 15px;
    top: auto;
    * {
        cursor: pointer;
    }
}
.dropzone.dz-started .dz-message {
    display: block !important;
    flex: 1;
    order: 1;
    height: 134px;
    margin: 0.5rem;
}
.navbar.fixed-top {
    box-shadow: 0 2px 9px 0 rgba(57,69,93,0.18);
    height: 64px;
}
.wizardPage .table-footer.page-footer {
        position: fixed;
        bottom: 64px;
}
.wizardPage .for-tbl-lbl > div:first-child {
    margin-bottom: 7rem!important;
}
.page-footer {
    left: 257px;
    right: 0;
    bottom: 0;
    transition: left 0.3s;
    height: 48px;
    overflow-x: auto;
    transform: scaleY(-1);
    box-shadow: inset 0 -1px 0 0 rgba(57,69,93,0.25), 0 2px 9px 0 rgba(57,69,93,0.1);
    overflow-y:hidden;
    z-index: 1111;
    position: fixed;
    left:0;
    right: 0;
    .page-footer-inner {
        transform: scaleY(-1);
    }
    @media (min-width: 768px) {
    }
    @media (max-width: 767px) {
        margin: 0;
    }
    @media(max-width: 991px){
    }
    @media (min-width: 991px) {
        left: 257px;
    }
}
.onboarding-footer.page-footer {
    left: 0!important;
}
.c-sidebar-minimized + .c-wrapper {
    .page-footer {
        left: 57px;
    }
}
.full-page-footer.page-footer {
    left:0;
    z-index: 10000;
    height: 64px;
}
.full-page-footer.page-footer
.select-option {
    background-color: #fff;
    color: inherit;
    cursor: default;
    display: block;
    font-size: inherit;
    padding: 8px 12px;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    &:active,
    &.selected {
        background-color: #2684ff;
        color: hsl(0, 0%, 100%);
    }
    &:hover {
        background-color: #deebff;
    }
}
.full-page-footer > div {
    transform: scaleY(-1);
}

.fancy-bg:before, .fancy-bg-lg:before {
    display: block;
    top: -255px;
    left: -680px;
    position: absolute;
    background: linear-gradient(-316.45deg, rgba(34,107,255,0) 0%, #014FEC 100%);
    opacity: 0.09;
    transform: rotate(-14deg);
    content: ' ';   
    height: 426px;
    width: 1360px;
    // z-index: -1;
}

.fancy-bg-lg:before {
    display: block;
    top: -255px;
    left: -680px;
    position: absolute;
    background: linear-gradient(-316.45deg, black 0%, red 100%);
    opacity: 0.09;
    transform: rotate(-14deg);
    content: ' ';   
    height: 426px;
    width: 1360px;
    // z-index: -1;
}

.card {
    box-shadow: 0px 0px 5px 0px #ccc;
}

.c-sidebar-nav-divider {
     height: initial!important;
     padding: initial!important;
     margin: 0.5rem 0.5rem!important;
     opacity: initial!important;
     visibility: initial!important;
 }

 .page-title-container {
     padding: 2rem 2.25rem 0 !important;
 }

 @media (min-width: 476px){    
    .modal-dialog:not(.modal-xl) {
        max-width: 400px;
        margin: .5rem auto;
    }

}
.modal-header {
    padding-bottom:1rem;
}

// :read-only {
//     background-color: transparent;
// }
.table td .dropdown {
    max-height: 20px;
    .btn {
        min-height: 20px;
        max-height: 20px;;
    }
}

.table-responsive {
    overflow-y: visible;
}
.is-invalid[class^="css-"][class$='-container'] > [class^='css-'][class$='-control'] {
    border-color: red!important;
}


.creatable-fixed-menu-option {
    height: 40px;
    width: 352px;
    border-radius: 0 0 3px 3px;
    background-color: #FFFFFF;
    box-shadow: inset 0 1px 0 0 #014FEC;
    color: #014FEC;
    border: none;
    font-family: Roboto;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
}



.payment_scrollable{
    max-height: 60vh;
    overflow: auto;
}

.payment_source_container {
    padding: 0.75rem 1.25rem;
    margin-bottom: 1.25rem;
    background-color: #f2f2f2;
    display: block;
       
    .dots:after {
        content: "\2807";
        font-size: 25px;
        margin-top: -5px;
        margin-right: -16px;
        cursor: pointer;
    }
    .card-info {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        margin-top: -15px;
        font-size: 23px;
        line-height: 27px;
    }
    .menu {
        width: 175px;
        // height: 95px;
        background-color: #ffffff;
        box-shadow: 0px 4px 9px rgba(0, 0 ,0 ,0.25);
        float: left;
        margin-top: 11px;
        z-index: 2;
        margin-left: 14%;
        position: absolute;
        right: 30px;
        top:10px;

    }
    .menu-item {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        line-height: 18px;
        color: black;
        padding: 6px 16px;
        cursor: pointer;
    
    }
    .separator{
        height: 1px;
        // margin-top: 6px;
        // margin-left: 15px;
        background-color: #C4C4C4;
        width: 100%;
    }

}


  // .CardBrandIcon-container {
  //   margin-left: 93% !important;
  // }
  .StripeElement{
    width: 100%;
    height: 100%;
    // padding: 0.5rem 0.75rem;
    input {
      
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
    }
  }
  
  .InputElement {
    font-size: 18px;
    font-family: "Roboto, Open Sans, Segoe UI, sans-serif";
    color: #3c4b64;
  }
  

  .table.foot-row tbody tr:last-child{
    font-weight: bold;
    box-shadow: none;
  }

.react-datepicker-wrapper.form-control.is-invalid {
    background: none;
    padding-right:0!important;
}

.mh-200 {
    min-height: 200px;
}

  

// --------------changes for responsive---Rajesh

.checkbox-align.row {
    flex-wrap: nowrap!important;
}
@media only screen and (max-width : 540px){
    // .full-page-footer.page-footer .full-page-footer>div{
    //     margin-top: -5px;
    // }
    .page-footer{
        position: fixed;
        margin: 0 !important;
    }
}
@media only screen and (max-width : 450px){
    .checkbox-align div {
        width: 7%;
        margin-left: 12px;
    }
    .checkbox-align p {
        width: 88%;
    }
    .footer-button>div{
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
    .footer-button button{
        font-size: 13px;
    }
    .dash-search-field {
        min-width: 180px !important;
        margin-top: 0 !important;
    }
    .del-but {
        margin-left: 0 !important;
    }
    .action-bar form {
        width: 100% !important;
    }
    .admin-histotycal-date>div {
        max-width: 175px !important;
    }
    .admin-claims-history>button{
        left: 65px;
    }  
    .page-footer {
        height: 75px !important;
    }
    .flatpickr-days {
        width: 309.875px !important;
    }
    .flatpickr-weekdays {
        width: 100% !important;
    }
    .comp-details div:nth-child(2) div:nth-child(1){
        //display: block !important;
    }
    .comp-details div:nth-child(2) div:nth-child(2){
        //right: 0;
        //position: absolute;
        //top: 0;
    }
    .for-mob-res {
        width: 63% !important;
    }
    .button-for-mobile button {
        padding: 12px !important;
    }
}
@media only screen and (max-width : 414px){
    .for-tbl-lbl .flatpickr-input {
        min-width: 200px !important;
    }
}
@media only screen and (max-width : 390px) {
    .admin-claims-history>button{
        left: 45px;
    } 
    // .full-page-footer {
    //     display: flex;
    //     justify-content: center;
    // }
    .button-for-mobile button {
        padding: 6px !important;
    }
}
@media only screen and (max-width : 280px){
    .button-for-mobile button {
        font-size: 9px !important;
        min-height: 30px !important;
    }
    .page-footer {
        height: 65px !important;
    }
}
