.btn {
    min-height: 40px; 
    border-radius: 4px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0;
    font-family: Roboto;
    padding: 0.625rem 1.125rem;
}
.btn-light{
    font-size: 15px;
}
.btn-primary {
    border: 1px solid rgba(0,0,0,0.3);  
    font-size: 15px;
    color: $white;
    
    &:hover, &:active, &:focus {
        background-color: darken($primary, 10%);
    }
}
.btn-link {
    color: $primary;
    &:hover,
    &:active,
    &:focus {
        color: darken($primary, 20%);
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
}
.btn-secondary, .btn-default {
    border: 1px solid rgba(57,69,93,0.2);
    background-color: #FAFAFA;
    color: var(--color);
    &:hover, &:active, &:focus {
        background-color: #fff;
        color: darken($gray, 20%);
    }
}