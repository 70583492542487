/** @format */

// If you want to override variables do it here
@import 'variables';

// Import CoreUI styles
@import '~@coreui/coreui/scss/coreui.scss';

// Import Spinkit styles
@import '~spinkit/spinkit.min.css';

// If you want to add something do it here
@import 'typography';
@import 'custom';
@import 'components/badge';
@import 'components/button';
@import 'components/form';
@import 'components/table';



/** APP STYLES **/


.c-app {
    --sk-color: #{$primary} !important;
    --primary: #{$primary} !important;
    // --secondary: 
    --success: #{$success} !important;
    --info: #{$info} !important;
    --warning: #{$warning} !important;
    --danger: #{$danger} !important;
    --light: #{$light} !important;
    --dark: #{$dark} !important;
    --color: $c2;
    color: $c2;
    font-family: Roboto;
}
// @media (min-width: 768px) {
    .c-main {
        padding-top:0rem;
//         > .container-fluid, 
//         > .container-sm, 
//         > .container-md, 
//         > .container-lg, 
//         > .container-xl, 
//         > .container-xxl {
//             padding-right: 24px;
//             padding-left: 24px;
        }
//     }
// }


.limited-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
p {
    font-size: 0.9375rem;
    line-height: 1.25rem;
}

/** TAB HEADERS **/

.nav.nav-tabs {
    padding:0 ;
    border-bottom: none;
    .nav-item {
        margin-bottom:0;
        margin-right:16px;
        .nav-link {
            line-height: 1.5;
            color: #{$gray-750};
            letter-spacing: 0;
            font-size: 15px;
            &.active {
                line-height: 1.25;
                font-weight: 500;
                color: var(--primary)
            }
        }
    }
}
.nav-tab-wrapper {
    box-shadow: 0 2px 9px 0 #{$gray-100};
    .nav-tab-inner-wrapper {
        box-shadow: inset 0 -1px 0 0 #{$gray-250}, 0 2px 9px 0 #{$gray-100};
        padding: 1.25rem 1.5rem 0;
        h1 {
            margin-bottom: 1rem;
        }
    }
}

h3, .h3 {
    line-height: 22px;
    font-size:17px;
    font-weight: 500;
    margin:0;
}
h4, .h4 {
    font-size: 0.9375rem;
    line-height: 1.25rem;
    font-weight: 400;
    margin-bottom: 0;
}

.sidebar-logo-wrapper {
    img {
        position: absolute;
        top: 0.5rem;
        left:0.5rem;
        transition: all 0.5s linear;
    }
    .logo-short {
        opacity:1;
    }
}
.c-sidebar-minimized {
    .logo-large {
        opacity:0;
    }
    .logo-short {
        opacity:1;
    }
}

.c-sidebar-nav {
    .c-sidebar-nav-item {
        // margin-top: 0.125rem;
        margin-bottom: 0.125rem;
        .c-sidebar-nav-link {
            padding:0.75rem 1.25rem;
            line-height: 20px;
            font-size: 15px;
            letter-spacing: 0;
            position: relative;
            &.c-active {
                font-weight: bold;
                background-color: rgba(0,0,0,0.1);
                // &:after {
                //     position: absolute;
                //     right:0;
                //     top:0;
                //     bottom: 0;
                //     width: 20px;
                //     background: red;
                //     content: url('<svg class="icon x16" width="16" height="16">
                //     </svg>')
                // }
            }
            &:hover, &.c-active:hover {
                background-color: rgba(0,0,0,0.2)
            }
        }
    }
}
.c-sidebar-nav-divider.visible-divider {
    height: 1px!important;
    opacity: 0.3!important;
    background-color: $white;
    margin-bottom: 1rem!important;
}
.c-sidebar-nav-divider.half-divider {
    margin-top:0.25rem!important;
    margin-bottom:0.25rem!important;
}

html:not([dir="rtl"]) .c-sidebar-nav-icon:first-child {
    margin-left:0;
    max-width: 1rem;
    margin-right: 0.5rem;
}


.text-primary {
    color: var(--primary)!important;
}
.text-dark {
    color: var(--dark)!important;
}
.text-success {
    color: var(--success)!important;
}
.text-danger {
    color: var(--danger)!important;
}
.text-warning {
    color: var(--warning)!important;
}
.text-muted {
    color: var(--muted)!important;
}


.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl, .col-xxl-auto {
    padding-left: 12px;
    padding-right: 12px;
}
.row {
    margin-left: -12px;
    margin-right: -12px;

}
.container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    padding-left: 12px;
    padding-right: 12px;
}

.card {
    box-sizing: border-box;
    border: 1px solid rgba(57,69,93,0.25);
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 9px 0 rgba(57,69,93,0.1);
    .card-body {
        padding: 1.5rem;
    }
}
h2, .h2 {
    font-size: 1.375rem;
    line-height: 1.75rem;
    margin-bottom: 0.25rem;
}
.claim-card {
    .title {
        display: flex;
        div > .form-check-input {
            // display: none;
            width: 1.25rem;
            height: 1.25rem;
            border: 1px solid #{$gray-250};
            border-radius: 3px;
            background-color: #FFFFFF;
            + *::after {
                content: " ";
                display: inline-block;
                vertical-align: bottom;
                width: 1rem;
                height: 1rem;
                border-radius: 10%;
                border-style: solid;
                border-width: 0.1rem;
                border-color: gray;
            }
        }
    }
    .claim-attachment{
        flex-shrink: 0;
        height: 140px;
        width: 140px;
        border-radius: 6px;
        background-color: rgba(57,69,93,0.1);
        box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
        overflow: hidden;
        img {
            width:100%;
            height: 100%;
            object-fit: contain;
        }
        + div[data-tippy-root] {
            border-radius: 3px!important;
            // max-width: 400px;
            // max-height: 400px;
            max-width: 80vw;
            max-height: 80vh;
            overflow: hidden;
            border: none;
            background-color: #FFFFFF!important;
            box-shadow: 0 6px 23px 0 rgba(57,69,93,0.75);
            .tippy-box {
                max-width: none!important;
                border: none;
                border-radius: 3px!important;
                background-color: #FFFFFF!important;
            }
            // @media (max-width:400px) {
            //     max-width: 100vw;
            // }
            // @media (max-height:400px) {
            //     max-width: 100vh;
            // }
            @media (max-width:400px) {
                max-width: 100vw;
            }
            @media (max-height:400px) {
                max-width: 100vh;
            }
        }
    }
    &.active {
        border: 3px solid #{$primary};
    }
    .attachment-preview {
        min-width: 300px;
        min-height:300px;
        // max-width:600px;
        // max-height: 600px;
        img {
            // max-width:600px;
            // max-height: 600px;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}


.badge {
    height: 1.25rem;
    padding: 0.125rem 0.5rem ;
    border-radius: 3px;
    line-height: 1rem;
    font-size: 0.75rem;
    font-weight: 400;
}
.badge-primary {
    background-color: $primary;
    color: white;
}
.badge-info {
    background-color: $info;
    color: white;
}
.badge-danger {
    background-color: $red-bg;
    color: white;
}
.badge-warning {
    background-color: $yellow-bg;
    color: white;
}
.badge-dark {
    background-color: var(--dark);
    color: white;
}
.badge-light {
    background-color: var(--light);
}
.badge-purple {
    background-color: var(--purple);
}

.tippy-box {
  border-radius: 3px!important;
  background-color: #FFFFFF!important;
  box-shadow: 0 6px 23px 0 rgba(57,69,93,0.75);
  max-width: none;
}

.tippy-content {
    .popover-body {
        font-family: Roboto;
        color:$gray;
        padding: 0;
        .note-card {
            width: 400px;
            p {
                font-size: 15px;
                line-height: 20px;
            }
        }
    }
}


/* LIGHTBOX */

#lightbox {
    
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:before {
        z-index: -1;
        content: ' ';
        position: absolute;
        top:0;
        right: 0;
        left: 0;
        bottom: 0;
        opacity:0.92;
        background: radial-gradient(circle, rgba(11,14,22,0.47) 0%, #0B0E16 100%);
    }
    .close-button {
        position: absolute;
        z-index: 1;
        top: 1.5rem;
        right: 1.5rem;
        color: #fff !important;
        cursor: pointer;
    }
    .lightBoxStep {
        width: 3rem;
        height: 3rem;
        position: absolute;
        top: calc(50% - 1.5rem);
        background: rgba(0,0,0,0);
        border-color: rgba(0,0,0,0);
        outline: none!important;
        color: $white;
        &.stepLeft {
            left: 1.5rem;
        }
        &.stepRight {
            right: 1.5rem;
        }
        &:hover {
            color: darken($white, 20%);
        }
    }
    .download-button {
        position: absolute;
        z-index: 1;
        top: 2rem;
        right: 3.5rem;
    }
    .content-area {
        position: absolute;
        left: 1.5rem;
        right: 1.5rem;
        padding-right: 3.5rem;
        padding-left: 3.5rem;
        top:5rem;
        bottom: 0;
        overflow: auto;
        flex-direction: column-reverse;
        flex-wrap: nowrap;
        .section {
            // min-height: calc(100vh - 10rem);
            width: calc(100vw - 7rem);
            &.detail-view {
                background-color: transparent;
                padding-top:1.5rem;
                padding-bottom: 1.5rem;
            }
            &.file-view {
                // overflow: hidden;
                img {
                    box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
                    border-radius: 6px;
                    background-color: $white;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
        @media (max-width: 992px) {
            // justify-content: start;
            padding-right: 2rem;
            padding-left: 2rem;
            .section {
                width: 100%;
            }
            .file-view {
                margin-top: 1rem;
                margin-bottom: 1rem;
            }
        }
        @media (min-width: 992px) {
            flex-direction: row;
            overflow: visible;
            top: 3rem;
            .detail-view {
                margin-top:-3rem;
                padding-top:1rem;
                height: calc(100vh);
                overflow-y: auto;
                padding-top: 2.25rem!important;
            }
            .file-view {
                height: calc(100vh - 6.5rem);
            }
        }
    }
}


.employee-wallet-card.card {
    width: 400px;
    clear: both;
    border: none;
    margin-bottom: 4.5rem;
    
    box-shadow: 0 2px 9px 0 rgba(57,69,93,0.18);
    @media(max-width: 500px) {
        max-width: 100%;
    }
    .card-body {
        padding: 1rem;
    }
    .caption {
        line-height: 1rem;
        font-size: 0.75rem;
        color: var(--muted);
        margin-bottom: 0.125rem;
    }
    .new-claim-button {
        right: 1rem;
        bottom: 1rem;
    }
}


.claimFormModal {
    @media (min-width: 769px) {
            .modal-dialog {
                width: 650px;
                min-width: 650px;
            }
    }
    @media (max-width: 768px) {
        .claimFormModalWrapper {
            flex-direction: column-reverse;
            > div {
                width: 100%!important;
            }
        }

    }
    .card {
        box-shadow: 0 3px 15px 0 rgba(0,0,0,0.08);
        border-bottom: 0.9365rem;
        width: 250px;
        border-radius: 3px;
        + .card {
            width: 400px;
        }
    }
}

.modal-header,
.modal-body,
.modal-footer {
    padding: 1.5rem;
    position: relative;
    .close {
        position: absolute;
        top:0;
        right: 0;
        width: 2rem;
        height: 2rem;
        padding: 0.5rem;
        margin: 0!important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}


.action-bar{
        margin-top:-10px;
    > * {
        padding-top:10px;
    }
}

.react-datepicker-popper {
    z-index:9999999!important;
}
// -------------css changes----------------- 
.dash-topup {
    position: relative;
    margin-top: 20px;
    padding-top: 20px;
}
.dash-topup::before{
    content: "";
    position: absolute;
    width: 100%;
    max-width: 95%;
    background: #0B0E16;
    height: 2px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}
.modal-dialog {
    margin-bottom: 70px !important;
}
// .border-dark {
//     border-width: 2px !important;
// }
.action-bar form {
    width: 40%;
    justify-content: space-between;
    @media (max-width: 1000px) {
        width: 100% !important;
    }
}
// .c-app.c-default-layout {
//     overflow: hidden;
// }

.btn-default:focus{
    outline: none;
    box-shadow: none !important;
}
.for-align tbody tr td:nth-child(2){
    width: 320px;
    max-width: 308px;
    text-align: left !important;
}
.for-align tbody tr td {
    text-align: right;
}
.for-align tbody tr td:nth-child(4){
    visibility: hidden;
    width: 160px;
    max-width: 160px;
    background: #0B0E16;
}
.for-align tbody tr td:nth-child(6) {
    width: 200px;
    max-width: 118px;
}
// .for-align tbody tr td:not(:first-child){
//     text-align: right;
// }
.for-align tbody tr td:nth-child(1){
    text-align: left !important;
}
.for-align tbody tr td:last-child {
    visibility: hidden;
    width: 39px;
    max-width: 33px;
}
// .for-align tbody tr td:nth-child(7){
//     visibility: hidden;
//     width: 200px;
//     max-width: 135px;
//     background: #0B0E16;
// }
.for-table-height {
    height: calc(100vh - 46vh) !important;
    // overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;
}
.tbl-footer {
    position: relative;
    overflow: overlay;
    /* margin-bottom: 48px; */
    height: 27px;
}
.for-freeze-colomn tbody tr td:nth-child(1),
.for-freeze-colomn thead tr th:nth-child(1) {
    position: sticky !important;
    left: 0;
    background: #fff;
    z-index: 11;
    border-bottom: 1px solid #0000000d;
}
// .for-freeze-colomn thead {
//     position: fixed !important;
// }
input#billing_postal_code ,.onboard-ps #mailing_postal_code {
    text-transform: uppercase;
}
table.table.table-hover.for_alignment tr th:nth-child(1), 
table.table.table-hover.for_alignment tr th:nth-child(2) {
    width: 200px !important;
}
table.table.table-hover.for_alignment tbody tr td:nth-child(1), 
table.table.table-hover.for_alignment tbody tr td:nth-child(2), 
table.table.table-hover.for_alignment tbody tr td:nth-child(3), 
table.table.table-hover.for_alignment tbody tr td:nth-child(4) {
    width: 200px !important;
    // max-width: 200px inherit;
    max-width: 200px !important;
}
table.table.table-hover.for_alignment tr th:nth-child(1), 
table.table.table-hover.for_alignment tr th:nth-child(2), 
table.table.table-hover.for_alignment tr th:nth-child(3) {
    width: 200px !important;
    max-width: 200px !important;
}
.topup-card-body footer button:nth-child(2){
    display: none;
}
.a-claim-his #lightbox button {
    display: none;
}
.a-billing-topup #lightbox .col-lg-3 button{
    display: none;
}
.a-billing-monthlyStatement #lightbox .col-lg-3 button {
    display: none;
}
.employeer-dash-tolltip label span {
    position: absolute;
    top: -10px;
}
.for-tbl-lbl .table tbody tr label{
    display: none;
}
.for-tbl-lbl .table tbody tr td label {
    display: block !important;
}
.emplyeer-dass-tbl-hover .table tbody tr:hover{
    background: none;
}
.for-search-none .search-field{
    display: none;
}
.for-claim-action-bar {
    display: none;
}
.tooltip-bar td {
    display: none;
}
.eligiboilty-contactform .form-group:nth-child(2),
.eligiboilty-contactform .form-group:nth-child(3),
.check-eligibility-contact-form .form-group:nth-child(1){
    display: none;
}
.for-header-table div:nth-child(2) p:nth-child(5),
.for-header-table .font-weight-bold p:nth-child(5),
.for-header-table .font-weight-bold p:nth-child(4)
{
    text-transform: uppercase;
}
// .employeer-statements-header div:nth-child(6) div:nth-child(2) {
//     display: flex;
// }
.for-header-table div:nth-child(6) div:last-child,
.for-header-table div:nth-child(5) div:last-child {
    max-width: 116px;
}
.my-busyness .for-header-table div:nth-child(5) div:last-child {
    max-width: 150px !important;
}
// .for-employee-header:



     
.block_Field{
    // border : 2px solid rgb(204, 204, 204);
    // background-color: rgb(236, 236, 236);
    pointer-events: none;
}
// .block_Field:focus{
//    border : 2px solid rgb(204, 204, 204);
//    background-color: rgb(236, 236, 236);
// }


 
.Check_Form > form{
    width: 85% !important;
 }

 .nav_dis{
     display: flex;
 }
 .eligibility_{
    margin-left: 3rem ;
    padding : 3rem ;
}


//
.historical_table > .tbl-footer{
    overflow : hidden !important;
    display: none !important;
    height: 0px !important;
}

@media only screen and (max-width : 280px){
    .nav_dis {
        display : block !important;
    }
    .T_nav {
        height: 105px !important;
    }
    .eligibility_{
        margin-left: 0px !important;
        padding : 3rem !important;
        margin-top: 3.5rem !important;
    }
    .eligi_form{
        width: 118%;
    }
    
}

.dis_claim{
    display: flex;
    flex: 1;
}

.mob_add_employ{
    margin-left: 1.5rem;
}

.mob_add_employ{
    margin-left: 1.5rem;
}






.table-res > .table-responsive{
    width: 100% !important;
    overflow-x: auto !important;
}

.table-res > .table-responsive::-webkit-scrollbar {
    height: 12px;
  }



  .table-res > .table-responsive::-webkit-scrollbar-track {
    background: #ffffff;
  }


  .table-res > .table-responsive::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.26);
    border-radius: 10px;
    border: 3px solid #ffffff;
  }
   

 
   @media only screen and (max-width : 280px){
       .nav_dis{
           display : block !important;
       }
       .T_nav{
           height: 105px !important;
       }
       .eligibility_{
           margin-left: 0px !important;
           padding : 3rem !important;
           margin-top: 3.5rem !important;
       }
       .eligi_form{
           width: 118%;
       }
}



.term_link:hover{
    text-decoration: none;
    color: #014FEC;
  }

 

  .tableScroll::-webkit-scrollbar {
    width: 12px;
  }

  .tableScroll::-webkit-scrollbar-track {
    background: #ffffff;
  }

  .tableScroll::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.26);
    border-radius: 10px;
    border: 3px solid #ffffff;
  }

.Invoices_table > .table-responsive > table > tbody > tr > label{
    display: none;
}
 .Invoices_status{
    padding-left: 63px;
}


html:not([dir="rtl"]) .c-sidebar:not(.c-sidebar-right) {
    margin-left: 0px !important;
}





@media only screen and (max-width : 500px){
    .navbar {
        position: fixed !important;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 0 !important;
    }
    .navbar-brand {
        display: inline-block;
        padding-top: 0.33594rem;
        padding-bottom: 0.33594rem;
        margin-right : 0px !important;
        font-size: 1.09375rem;
        line-height: inherit;
        white-space: nowrap;
    }
    .con_nav{
        padding: 0.5rem 0 !important;
    }
    .eligi_form{
        width: 100%;

    }
    .page-footer {
        margin: 0 0 !important;
        position: fixed !important;
    }
    .welcome{
        width: 100% !important;
        overflow: hidden !important;
    }
    .dash_{
        display: block !important;
    }
    .cal_icon{
        position: absolute;
        right: 8px;
        margin-top: -14px;
    }
    .dis_claim{
        // display: block !important;
        min-width: 280px !important;
        justify-content: start!important;
        flex-wrap: wrap;
    }
    .mob_employee{
        margin-left: -22px;
        width: calc(100% + 44px);
    }
    .mob_add_employ{
        margin-top: 10px;
        margin-left: 16px !important;
    }
    .flatpickr-calendar.open {
        width: 75% !important;
    }
    //  .flatpickr-weekdays{
    //     width: 50% !important;
    // }
    .claim_mob{
        width: 100%;
    }
    .imp_but{
        margin-top: 10px;
        // width: 180px !important;
    }
    .scrollable_tab{
        min-width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
    }
    .tab-content {
        padding-left: 10px!important;
        padding-right: 10px!important;
    }
}

@media only screen and (max-width : 1200px){
    .step2_field{
        display: block !important;
    }
  }


  @media only screen and (max-width : 992px){
    .burger-wrapper{
        width: 40px !important;
        height: 38px !important;
        background-color: #014FEC;
        position: absolute;
        left: 90px !important;
        border-bottom-right-radius: 3px !important;
        border-top-right-radius : 3px !important;
        display: flex !important;
        align-items: center !important;
        cursor: pointer;
    
        .bars{
            color: white !important;
            font-size: 20px;
            margin-left: 11px !important;
        }
    }
    .header-with-actions {
        flex-direction: column-reverse;
        .dis_claim {
            flex-direction: column;
            align-items: flex-start!important;
            align-self: flex-start;
        }
    }

}
.side_nav{
    width: 90px;
    z-index: 9999 !important;
}
.nav-tab-wrapper .nav-tab-inner-wrapper h1 {
    margin-top: 1rem !important;
}
@media only screen and (max-width : 450px){
    .admin_addE{
        text-align: left !important;
    }
    .align_class{
        justify-content: flex-start !important;
        margin-left: -25px !important;
    }
    .mob_export{
        margin-bottom: 12px;
    }
    .edit_modal > .modal-dialog > .modal-content{
        
        min-width: 57% !important;
    }
    .edit_modal{
        min-width: 100px!important;
    }
    .edit_modal > .modal-dialog{
        min-width: 100px;
    }
}
.terms_con > .form-group > .invalid-feedback{
    position: absolute;
    left: 6px;
    top: 25px;
}
@media only screen and (max-width : 1500px){
    .terms_con > .form-group > .invalid-feedback{
        top: 40px;
    }

}
@media only screen and (max-width : 420px){
    .terms_con > .form-group > .invalid-feedback{
        top: 65px;
    }
}
@media only screen and (max-width:450px){
    .his_expo{
        height: 40px !important;
        margin-left:30px !important;
        position: relative !important;
        left: -5px !important;
        top: 10px !important;
    }
    .ex-emp{
        margin-top: 18px !important;
    }
    .block_d{
        display: block !important;
    }
    .us_down{
        margin-top: 10px !important;
        margin-left: 0px !important;
    }
    .mon_date{
        margin-bottom: 20px !important;
        margin-left: -11px !important;
    }
}
@media only screen and (min-width : 420px){
    .his_expo{
        height: 40px;
        margin-left: 30px;
        margin-top: 10px;
    }
}
@media only screen and (max-width : 336px){
    .terms_con > .form-group > .invalid-feedback{
        top: 92px;
    }
    .terms_con{
        margin-bottom: 26px;
    }
}

.checkbox-align.row {
    flex-wrap: nowrap!important;
}
.terms_con{
    flex-wrap : nowrap;
    margin-left: 0px;
    position: relative;
    margin-bottom: 20px;
}

.a-claim-his #lightbox button {
    display: flex !important;
}
.invoice_table > .table-responsive{
    overflow-x: hidden !important;
}

@media only screen and (max-width : 768px){
 
    .section1{
        margin-bottom: 115px;
    }
    .section2{
        margin-bottom: 115px;
    }
    .section3{
        margin-bottom: 115px;
    }
}
 

.Invoice_d {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.date-range > .form-control{
    margin-bottom: 10px;
}
@media only screen and (max-width:450px){
    .top_b{
        margin-top: 0px !important;
    }
    .Invoice_d{
        // display: block !important;
        // margin-left: -10px !important;
        // width: calc(100% + 20px);
    }
    .date-range{
        width: 100%;
    }
    .date-range > .form-control{
      width: 262px;
    }
     .cal_icon{
        position: absolute !important;
      top: 24px !important;
      right:54% !important;
    }
    .invoice_table > .table-responsive{
        overflow-x: scroll !important;
    }
}
.download_b{
    // width: 200px;
    height: 40px;
    margin-top: 10px;
    margin-right: 20px;
}

 
@media only screen and (max-width : 450px){
    .emp_screen{
        justify-content: start !important;
        align-items: start !important;
        
    }
    .em_screen{
        display: block !important;
    }
    .mob-light-view{
        margin-top: 14px !important;
    }

    .export_table > .table-responsive > .table{
        width: 700px !important;
    }
    .down-b{
        margin-bottom: 20px !important;
    }
    .exp-b{
        margin-top: 0px !important;

    }
    .check-dis{
        width:200px !important;
    }
}

@media only screen and (min-width : 450px){
    .us_down{
        margin-top:10px;
    }
}
.check_eli_{
    margin-top: 35px !important;
}

@media only screen and (max-width : 768px){
    .section1{
        margin-bottom: 115px;
    }
    .section2{
        margin-bottom: 115px;
    }
    .section3{
        margin-bottom: 115px;
    }
}
 
 @media only screen and (max-width : 550px){
    .react-pdf__Page__canvas {
        overflow-y: scroll;
        display: block;
        user-select: none;
        width: 100% !important;
        height: auto !important;

    }
 }
 
//.for-align > thead{
//    visibility: hidden !important;
//}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999 !important;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

[data-tippy-root] {
    z-index: 999999!important;
}
.left-action-bar {
    @media (max-width:1000px) {
        min-width: 100%;
        .form {
            width: 100%;
        }
    }
}
canvas.react-pdf__Page__canvas {
    margin: auto;
}