/** @format */

@import '~@coreui/coreui/scss/variables/colors.scss';


$white: #FFFFFF;
$black: #000000;
$gray: rgba(57,69,93,1);
$gray-100: rgba(57,69,93,0.1);
$gray-200: rgba(57,69,93,0.2);
$gray-250: rgba(57,69,93,0.25);
$gray-300: rgba(57,69,93,0.3);
$gray-400: rgba(57,69,93,0.4);
$gray-500: rgba(57,69,93,0.5);
$gray-600: rgba(57,69,93,0.6);
$gray-700: rgba(57,69,93,0.7);
$gray-750: rgba(57,69,93,0.75);
$gray-800: rgba(57,69,93,0.8);
$gray-900: rgba(57,69,93,0.9);

$c1:#FFFFFF;
$c2:#39455D;
$c3:#014FEC;
$c6:#62CB8F;
$c7:#E3465A;
$c8:#D86977;
$c10:#0084F4;
$c11:rgba(0,0,0,0.3);
$c12:#EBA57C;
$c13:#E3A13A;
$c14:#000000;
$c15:#0B0E16;
$c16:rgba(0,0,0,0.1);
$c18:rgba(0,0,0,0.1);
$c19: #AB6AC9;

$light-blue: $c10;
$blue: $c3;
$gray: $c2;
$green: $c6;
$red: $c7;
$red-bg: $c8;
$yellow: $c13;
$yellow-bg: $c12;
$purple: $c19;



$primary: $blue;
$info: $light-blue;
$success: $green;
$danger: $red;
$warning: $yellow;
$header-color: $c2;
$sk-color: $primary;
$light: $c1;
$dark: $c15;
$muted: $gray-750;

:root {
    --sk-color: #{$primary} !important;
    --primary: #{$primary} !important;
    --success: #{$success} !important;
    --warning: #{$warning} !important;
    --danger: #{$danger} !important;
    --light: #{$light} !important;
    --dark: #{$dark} !important;
    --muted: #{$muted} !important;
    --info: #{$info} !important;
    --purple: #{$purple} !important;
    --color: #{$gray} !important;
}
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "info":       $info,
    "success":    $success,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark,
    "muted":      $muted,
    "purple":     $purple,
  ),
  $theme-colors
);

$text-color: $c15;